@import 'react-toastify/dist/ReactToastify.css';

.app {
  display: flex;
  height: 100vh;
  font-family: Arial, sans-serif;
}

.menu {
  width: 200px;
  background-color: #333;
  color: white;
  padding: 20px 0;
}

.menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.menu li {
  padding: 10px 20px;
}

.menu a {
  color: white;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.menu a svg {
  margin-right: 10px;
}

.main-content {
  display: flex;
  flex-grow: 1;
}

.left-column {
  width: 300px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #f0f0f0;
}

.right-column {
  flex-grow: 1;
  padding: 20px;
  overflow-y: auto;
}

.drop-zone {
  border: 2px dashed #ccc;
  padding: 20px;
  text-align: center;
  margin-bottom: 20px;
  cursor: pointer;
}

#price-files-list {
  margin-top: 20px;
  max-height: 200px;
  overflow-y: auto;
  padding: 10px;
  background-color: #e8f5e9;
  border: 1px solid #a5d6a7;
  border-radius: 4px;
  font-size: 14px;
}

.uploaded-spec-file {
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 10px;
  background-color: #e8f5e9;
  border: 1px solid #a5d6a7;
  border-radius: 4px;
  font-size: 14px;
}

.uploaded-spec-file .file-name {
  font-weight: bold;
}

.spec-filter-container {
  margin-top: 20px;
}

.spec-filter-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.search-button {
  margin-top: 20px;
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
}

/* Toast customization */
.Toastify__toast-container {
  z-index: 9999;
}

.Toastify__toast {
  font-family: Arial, sans-serif;
  color:white;
}

.Toastify__toast--success {
  background: #4CAF50;
}

.Toastify__toast--error {
  background: #f44336;
}

/* File management */


.file-management {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  overflow: scroll;
}

.file-upload-section,
.file-list-section {
  margin-bottom: 20px;
}

.dropzone-container {
  display: flex;
  justify-content: space-between;
}

.dropzone {
  width: 48%;
  height: 150px;
  border: 2px dashed #ccc;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  transition: border .3s ease-in-out;
}

.dropzone:hover {
  border-color: #4CAF50;
}

.file-table {
  width: 100%;
  border-collapse: collapse;
}

.file-table th,
.file-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.file-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.status-icon {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 5px;
}

.status-processed { background-color: #4CAF50; }
.status-processing { background-color: #2196F3; }
.status-processing-error { background-color: #F44336; }
.status-unprocessed { background-color: #9E9E9E; }

.view-button {
  background-color: #4CAF50;
  color: white;
}

.action-button {
  margin-right: 5px;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.parse-button { background-color: #2196F3; color: white; }
.edit-button { background-color: #FFC107; }
.delete-button { background-color: #F44336; color: white; }

.action-button:disabled {
  background-color: #9E9E9E;
  cursor: not-allowed;
}
